import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './ui/App';
import League from './handlers/League';
import reportWebVitals from './reportWebVitals';
import {Load, Reset} from './handlers/Storage';

let league: League | undefined;

export async function init(reset: boolean){

	ReactDOM.render(
		<React.StrictMode>
			<div></div>
		</React.StrictMode>,
		document.getElementById('site-content')
	);

	let data;

	if(reset){
		data = await Reset(2022);
	}else{
		data = await Load(2022);
	}

	league = new League(data);

	ReactDOM.render(
		<React.StrictMode>
			<App league={league} />
		</React.StrictMode>,
		document.getElementById('site-content')
	);

}

init(false);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
